/* @import url("//fonts.cdnfonts.com/css/sf-pro-display"); */

@font-face {
    font-family: 'SF Pro';
    src: url('../src/assets/SF-Pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


body {
    font-family: "SF Pro";
    margin: 0;
    background-color: #171931;
    color: rgba(241, 241, 241, 1);
    display: flex;
    font-size: 14px;
    height: 100vh;
}

body>div {
    @media (max-height: 1000px) {
        zoom: 0.85;
    }
}

#root {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-between;
    flex-direction: column;
    flex-grow: 1;
}

.react-calendar {
    background-color: transparent !important;
    margin: 0 auto;
    border: 1px solid #171931 !important;
}

.react-calendar>button:hover {
    background: #171931 !important;
}

.react-calendar,
.react-calendar__tile,
.react-calendar__navigation button {
    font-family: inherit !important;
}

.react-calendar__tile:disabled {
    background: inherit !important;
    opacity: 0.4 !important;
}

.react-calendar__tile:hover {
    background: rgba(67, 133, 243, 1) !important;
    border-radius: 50%;
}

.react-calendar__tile--now {
    background: #FFC700 !important;
    border-radius: 50%;
}

.react-calendar__tile--active {
    border-radius: 50%;
    background: #42A776 !important;
}

.react-calendar__month-view__days__day--weekend {
    color: inherit !important;
}

button {
    color: white;
}

.rbc-row-segment .rbc-event-content {
    word-wrap: break-word;
    white-space: break-spaces !important;
}

.rbc-label,
.rbc-header {
    color: black;
}

.rbc-calendar,
.rbc-toolbar {
    font-size: 12px;
}

.rbc-timeslot-group {
    min-height: 27px;
}

.rbc-current-time-indicator {
    background-color: #b53434 !important;
    height: 2px !important;
}

.rbc-off-range-bg {
    /* set background for off days here */
}

a:visited,
a:hover,
a {
    text-decoration: none;
    color: #4385F3 !important;
}

.tooltip {
    color: black !important;
}

.besafe-calendar.rbc-calendar,
.besafe-calendar.rbc-calendar .rbc-label,
.besafe-calendar.rbc-calendar .rbc-header,
.besafe-calendar.rbc-calendar .rbc-toolbar {
    color: white !important;
}

.rbc-toolbar button {
    background: #4385F3 !important;
    border-color: #4385F3 !important;
    color: white !important;
}

.rbc-toolbar button:hover {
    background: rgba(23, 25, 49, 1) !important;
    border-color: rgba(23, 25, 49, 1) !important;
}

.rbc-time-view,
.rbc-time-content>*+*>*,
.rbc-day-slot .rbc-time-slot,
.rbc-time-header.rbc-overflowing,
.rbc-header+.rbc-header,
.rbc-time-header-content,
.rbc-timeslot-group,
.rbc-time-content,
.rbc-day-bg,
.rbc-header,
.rbc-month-view,
.rbc-month-row+.rbc-month-row {
    border-color: rgba(41, 44, 84, 1) !important;
}

.rbc-off-range-bg {
    background-color: rgba(7, 8, 16, 1) !important;
}

.besafe-calendar.rbc-calendar .rbc-today {
    background-color: #171931 !important;
}

.slot-default {
    border: none;
}


.map-label,
.map-notification {
    border: 1px solid #fff;
    font-family: "Impact";
    border: 1px solid rgba(96, 96, 96, 1);
    background: rgba(96, 96, 96, 1);
    color: #fff !important;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    font-size: 11px !important;
    width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200;
}

.privacy {
    filter: blur(0.2rem);
}

.map-notification {
    background: rgba(181, 52, 52, 1);
    border: 1px solid rgba(181, 52, 52, 1);
    font-weight: 400;
}

.horizontal-slider {
    padding: 1rem 0;
}

.horizontal-slider .example-thumb {
    border: 1px solid white;
    top: 1px;
    padding: 0.25rem 0.5rem;
    background: rgba(41, 44, 84, 1);
    cursor: pointer;
}

.horizontal-slider .example-track {
    background: rgba(41, 44, 84, 1);
    /* top: 10px; */
    height: 5px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: rgba(16, 18, 36, 1);
}

select:focus>option:checked {
    background: #000 !important;
}